.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.card {
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.card h1 {
  margin-bottom: 1.5rem;
  color: #333;
  font-weight: 700;
}

.p-field {
  margin-bottom: 1.5rem;
  text-align: left;
}

.p-field label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #555;
}

.p-inputtext,
.p-password-input {
  width: 100%;
  padding: 0.75rem;
  border-radius: 4px;
  /* border: 1px solid #ddd; */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.p-button-primary {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 4px;
  background-color: #0069d9;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.p-button-primary:hover {
  background-color: #0053ba;
}
