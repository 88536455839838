.match-card {
  color: white;
  border-radius: 10px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure the card takes the full height of its container */
}

.match-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.club-logo {
  height: 40px;
  width: 40px;
}

.division-name {
  flex: 1;
  text-align: center;
}

.price-badge {
  text-align: right;
}

.match-card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  margin-top: auto; /* Pushes the body to the bottom of the card */
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.match-date-time {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.match-date-time .pi {
  margin-right: 5px;
}

.match-time {
  margin-left: auto;
}

.match-status {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.match-status .pi {
  margin-right: 5px;
}

.closed-status {
  color: red;
}

.match-location {
  margin-top: 5px;
  font-size: 0.9em;
}
