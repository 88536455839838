.ranking-container {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
}

.filters {
    border-radius: 8;
    padding-inline: 6;
    margin-block: 8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter {
    display: flex;
    flex-direction: column;
}

.filter-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.filter-select {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.table-container {
    overflow-x: auto;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.ranking-table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'GlacialRegular';
}

.ranking-table th {
    background-color: #13499C;
    /* opacity: 85%; */
    color: #BDE83F;
    text-align: left;
    padding: 10px;
    font-size: 18px;
}

.ranking-table td {
    padding: 10px;
    border-bottom: 2px solid #13499C;
    font-size: 18px;
}

.ranking-table tr:nth-child(even) {
    background-color: #f5f5f5;
}

.position-header {
    width: 40px;
}

.position-cell {
    font-weight: bold;
    text-align: center;
}

.player-cell {
    display: flex;
    align-items: center;
    gap: 10px;
}

.player-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

.player-name {
    font-weight: bold;
}

.crown-icon {
    font-size: 1.2em;
}

.victory-cell {
    font-weight: bold;
}

.loading {
    text-align: center;
    padding: 20px;
    font-style: italic;
    color: #666;
}

@media (max-width: 480px) {
    .filters {
        justify-content: left;
        align-items: center;
    }

    .ranking-table th {
        font-size: 14px;
    }
}