.form-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #fff; /* Adjust as necessary */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-container h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .form-container .important-note {
    text-align: center;
    color: #d9534f; /* Adjust as necessary */
    margin-bottom: 2rem;
  }
  
  .p-fluid .p-field {
    margin-bottom: 1.8rem;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .form-container {
      padding: 1rem;
    }
  }
  