.info-item {
  display: flex;
  align-items: center;
}
.icon {
  color: #13499c;
  margin-right: 1rem;
  font-size: 1.25rem;
}
.label {
  font-size: 1rem;
  font-weight: 500;
  color: #6b7280;
  margin: 0;
}
.value {
  font-size: 1rem;
  color: #1f2937;
  margin: 0.25rem 0 0;
}

.profile-container {
  max-width: 720px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.profile-card {
  background-color: white;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.blue-section {
  background-color: #13499c;
  color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar {
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #6b7280;
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.name-section {
  text-align: center;
}
.name-section h1 {
  font-family: 'TTRunsBold';
  font-size: 1.5rem;
  font-weight: bold;
  color: #BDE83F
}
.name-section p {
  font-size: 1.5rem;
  color: #CFC8C4;
  margin: 0.25rem 0 0;
}
.info-section {
  padding: 1.5rem;
  display: grid;
  gap: 1.5rem;
}
@media (min-width: 640px) {
  .blue-section {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .name-section {
    margin-top: 0;
    margin-left: 1.5rem;
    text-align: left;
  }
}