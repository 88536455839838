.division-name {
  margin-left: auto;
  margin-bottom: -8px;
}

.division-badge {
  background: #13499C;
  color: #BDE83F;
  border-radius: 16px;
  padding-inline: 1.5rem;
  font-family: 'GlacialBold';
}

.results-container {
  display: flex;
  flex-direction: column;
  border: 2px solid #13499C;
  padding: 12px 14px;
  border-radius: 12px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 4px 0;
}

.player {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  margin: 2px;
}

.winner-info {
  flex: 0001px; /* Prevents growing and shrinking, sets a fixed width */
  display: flex;
  align-items: center;
  justify-content: center;
}

.winner-icon-container {
  background: #13499C;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.winner-icon {
  color: #BDE83F; 
  font-size: 20px
}

.set-results {
  display: flex;
  gap: 12px;
  flex: 2;
  justify-content: space-around;
}

.score {
  font-weight: bold;
  font-size: 1.8rem;
  text-align: center;
}

.custom-divider {
  display: flex;
  align-items: center;
  text-align: center;
  border-bottom: 2px solid #13499C;
  margin-bottom: 8px;
}

@media only screen and (max-width: 600px) {
  .results-container {
    padding: 12px 14px;
  }

  .row {
    padding: 4px 0;
  }
}