.feed-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.result-container {
  flex: 1 1 calc(25% - 16px);
  box-sizing: border-box;
  margin: 8px;
  max-width: calc(25% - 16px);
  min-width: 250px;
  overflow: hidden;
  height: auto;
}

@media (max-width: 1000px) {
  .result-container {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (max-width: 800px) {
  .feed-container {
    display: unset;
  }
}

@media (min-width: 1000px) {
  .body-results-container {
    border-left: 2px solid #13499C;
    border-right: 2px solid #13499C;
    border-bottom: 2px solid #13499C;
    border-top: 0px solid;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 12px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
  }
}