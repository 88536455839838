.input-small {
  height: 3rem;
  width: 2.5rem;
  margin-left: 0.5rem;
}

.container {
  min-width: 720px; /* Default for large screens */
}

@media (max-width: 768px) {
  .container {
    min-width: 0px; /* For screens smaller than 768px */
  }
}

.input-small .p-inputtext {
  font-size: 1.5rem;
}

.input-small .p-inputtext:enabled:hover {
  border-color: #13499C;
}

.input-small .p-inputtext:enabled:focus {
  border-color: #13499C;
}

.winner-info {
  flex: 0001px;
  /* Prevents growing and shrinking, sets a fixed width */
  display: flex;
  align-items: center;
  justify-content: center;
}

.winner-icon-container {
  background: #13499C;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.winner-icon {
  color: #BDE83F;
  font-size: 20px
}


@media only screen and (max-width: 420px) {
  .input-small {
    height: 2.3rem;
    width: 2.3rem;
    /* margin-left: 0.5rem; */
  }

  .input-small .p-inputtext {
    font-size: 1rem;
  }

  .winner-info {
    flex: 0001px;
    /* Prevents growing and shrinking, sets a fixed width */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .winner-icon-container {
    background: #13499C;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .winner-icon {
    color: #BDE83F;
    font-size: 15px
  }
}