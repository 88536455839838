.extra-info-container {
    background: #FFFF;
    border-radius: 12px;
    padding: 8px;
    border: 2px solid #13499C
}

.extra-info-title {
    color: #13499C;
    font-family: 'TTRunsBold';
}