.custom-phone-input .PhoneInputInput {
  width: 100%;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-family: 'GlacialRegular';
  font-size: medium;
}
  
.custom-phone-input .PhoneInputCountry {
  padding: 8px;
  margin-right: 8px;
}