.p-stepper {
  background-color: transparent !important;
  font-family: 'GlacialRegular';
}

.p-stepper-header {
  background-color: transparent;
  pointer-events: none;
}

.p-stepper .p-stepper-panels {
  margin-top: 1rem;
  padding: 0rem
}

.p-stepper-separator {
  background-color: #13499C
}

.p-stepper .p-stepper-header.p-highlight .p-stepper-number {
  background: #acc2e3;
}

.steps-action {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  gap: 1rem;
}

.steps-action .p-button {
  flex: 1;
  max-width: 45%;
}

.p-stepper .p-stepper-header .p-stepper-action {
  font-family: 'GlacialRegular';
}

@media (max-width: 768px) {
  .card {
    max-width: 100%;
    padding: 1rem;
  }

  .steps-action {
    flex-direction: column;
    gap: 1rem;
  }

  .steps-action .p-button {
    width: 100%;
    max-width: none;
  }
}