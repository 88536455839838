.empty-spot-text {
    color: #BDE83F;
}

.players-container {
    background: #13499C;
    border-radius: 12px;
    padding: 10px;
}

.leave-match-confirm-popup {
    font-family: 'GlacialRegular';
    max-width: 70%;
}

.leave-match-confirm-popup .p-confirm-popup-content {
    font-family: 'GlacialRegular';
    font-size: 1rem;
}

.leave-match-confirm-popup .p-confirm-popup-footer .p-confirm-popup-accept {
    background: #13499C;
}

.leave-match-confirm-popup .p-confirm-popup-footer .p-confirm-popup-reject {
    color: #13499C;
}