.division-filter-container {
  padding-left: 22px;
  display: flex;
  flex-direction: row;
  align-items: center; /* Align items vertically centered */
  gap: 10px; /* Add some space between the elements */
}

.available-matches-container {
  flex: 1 1 calc(25% - 16px);
  box-sizing: border-box;
  margin: 8px;
  max-width: 370px;
  min-width: 310px;
  overflow: hidden;
  height: auto;
}

@media (max-width: 1000px) {
  .available-matches-container {
    flex: 1 1 100%;
    max-width: 100%;
  }
}