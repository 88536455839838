.match-main-info-container {
    background: #13499C;
    border-radius: 12px;
    padding: 12px;
}

.info-container {
    margin-top: 4px;
    background: #FFF;
    border-radius: 12px;
    padding: 12px;
}

.title {
    color: #BDE83F;
    font-family: 'TTRunsBold';
}