.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  flex: 0 0 auto;
  position: sticky;
  top: 0px;
    z-index: 1;
}

main {
  flex: 1 1 auto;
  background-color: #FFFFFF;
}

footer {
  flex: 0 0 auto;
  padding: 1rem;
  background-color: #333;
  color: white;
  text-align: center;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.p-menubar-button{
  pointer-events: none;
}

.p-submenu-header{
  font-family: 'GlacialRegular';
}
.p-menuitem-text{
  font-family: 'GlacialRegular';
}

.main-content {
  padding: 1rem;
  padding-bottom: 8rem;
}

.main-content.home {
  padding: 0;
}

.main-content.mobile-authenticated {
  padding-bottom: 8rem;
}