.horizontal-menu {
    display: flex;
    flex-direction: column;
    background-color: rgb(246, 246, 246);
    border-bottom: 1px solid #dee2e6;
}

.menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 0.25rem;
    height: 60px;
}

.menu-logo {
    margin-right: 1rem;
}

.user-menu {
    margin-left: auto;
}

.menu-items {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0.25rem 0.5rem;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.menu-items::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.menu-link {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.5rem;
    color: #495057;
    text-decoration: none;
    white-space: nowrap;
    background: none;
    border: none;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    outline: none;
}

.menu-link:hover,
.menu-link:focus {
    color: #13499C;
}

.menu-icon {
    margin-right: 0.5rem;
}

@media (max-width: 768px) {
    .menu-items {
        width: 100%;
    }

    .menu-link {
        /* font-size: 0.9rem; */
    }
}