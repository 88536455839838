@font-face {
  font-family: 'GlacialRegular';
  src: url('./assets/GlacialIndifference-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GlacialBold';
  src: url('./assets/GlacialIndifference-Bold.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GlacialItalic';
  src: url('./assets/GlacialIndifference-Italic.otf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'TTRunsBold';
  src: url('./assets/TTRunsTrialBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: '#FFFFFF';
  overflow-x: hidden;
  font-family: 'GlacialRegular';
  font-weight: normal;
  background: var(--surface-ground);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
}

::-webkit-input-placeholder {
  color: #999;
}

:-moz-placeholder {
  color: #999;
}

::-moz-placeholder {
  color: #999;
}

:-ms-input-placeholder {
  color: #999;
}

.p-inputtext {
  font-family: 'GlacialRegular';
}

.p-fileupload .p-button {
  font-family: 'GlacialRegular';
  background-color: #13499C;
  color: #FFFFFF;
  border: 1px solid #13499C;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.p-fileupload :hover {
  background-color: #0d3a77;
  border-color: #0d3a77;
}

.primary-button {
  font-family: 'GlacialRegular';
  background-color: #13499C;
  color: #FFFFFF;
  border: 1px solid #13499C;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.primary-button:hover {
  background-color: #0d3a77;
  border-color: #0d3a77;
}

.primary-button:disabled {
  background-color: #cccccc;
  border-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.6;
}

.p-dropdown {
  border-radius: 8px;
}

.p-dropdown-trigger {
  background: #13499C;
  color: #FFFFFF;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.p-dropdown .p-inputtext {
  font-family: 'GlacialRegular';
  font-size: 1.1rem;
  border: 0;
  box-shadow: none;
  border-right: 1px solid #ddd;
  border-radius: 0;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: #999;
}

.p-datepicker-trigger {
  background: #13499C;
  color: #FFFFFF;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-calendar {
  border-radius: 8px;
}

.p-calendar-trigger {
  background: #13499C;
  color: #FFFFFF;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.p-calendar .p-inputtext {
  font-family: 'GlacialRegular';
  font-size: 1.1rem;
  box-shadow: none;
  border-right: 0px solid #ddd;
  border: 1px solid #ddd;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.p-calendar-trigger {
  background: #13499C;
  color: #FFFFFF;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.p-dialog-header {
  font-family: 'GlacialRegular';
}

.p-confirm-dialog-message {
  font-family: 'GlacialRegular';
}

.p-dialog-footer .p-confirm-dialog-reject {
  font-family: 'GlacialRegular';
  color: #13499C;
}

.p-dialog-footer .p-confirm-dialog-accept {
  font-family: 'GlacialRegular';
  color: #ffffff;
  background: #13499C;
  border: 1px solid #13499C;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
}