.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.check-circle-icon {
    font-size: 5rem;
    color: #4CAF50;
    margin-bottom: 1rem;
}

.modal-title {
    color: #4CAF50;
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.modal-text {
    line-height: 1.5;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    color: #333;
}

.close-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    font-family: 'GlacialRegular'
}

.close-button:hover {
    background-color: #45a049;
}

@media (max-width: 600px) {
    .modal-content {
        width: 95%;
        padding: 1.5rem;
    }

    .modal-title {
        font-size: 1.3rem;
    }

    .modal-text {
        font-size: 0.9rem;
    }
}