.feed-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-item {
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
}

.home-carousel .p-carousel-container {
  margin-inline: 0px;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  margin: 0rem;
}

.home-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #13499C;
}

@media (max-width: 480px) {
  .carousel-item {
    padding: 0rem
  }

  .feed-container {
    display: unset;
  }
}

@media (max-width: 800px) {
  .feed-container {
    display: unset;
  }
}

@media (min-width: 1000px) {
  .body-container {
    border-left: 2px solid #13499C;
    border-right: 2px solid #13499C;
    border-bottom: 2px solid #13499C;
    border-top: 0px solid;
    border-color: #13499C;
    padding: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}